import HttpService from "../../../axiosConfig/http.service";

export default class ReviewService extends HttpService {
  createReview = async (data) => {
    try {
      let response = await this.postRequest("/v1/reviews", data, {
        auth: true,
        file: true,
      });
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  listAllReviews = async (perPage = 8, page = 1) => {
    try {
      let response = await this.getRequest(
        "/v1/reviews?perPage=" + perPage + "&page" + page
      );
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  getReviewDetail = async (slug) => {
    try {
      let response = await this.getRequest("/v1/reviews/" + slug + "/detail");
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  deleteReview = async (id) => {
    try {
      let response = await this.deleteRequest("/v1/reviews/" + id, {
        auth: true,
      });
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  updateReview = async (data, id) => {
    try {
      let response = await this.putRequest("/v1/reviews/" + id, data, {
        auth: true,
        file: true,
      });
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  getReviewById = async (id) => {
    try {
      let response = await this.getRequest(`/v1/reviews/${id}`, {
        auth: true,
      });

      return response;
    } catch (exception) {
      throw exception;
    }
  };
}
