// import Sound from ""
import React, { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
//Importing All the pages or categories. *********************
import Home from "./pages/home/Home";
import About from "./pages/about/About";
// import Portfolio from "./pages/portfolio/Portfolio"
// import Pricing from "./pages/pricing/Pricing"
// import Services from "./pages/service/Services"
//End of importing pages**************************************
//Layout of the Whole site--------------------
import Layout from "./comp/layout/Layout";
import AdminLayout from "./pages/Admin-Dashboard/adminLayout";
import Login from "./comp/login/login";
import ForgetPassword from "./comp/forget-password/forget-password";
import SetPassword from "./comp/forget-password/set-password";
import ActivateUser from "./comp/login/activate";
import ReviewListPage from "./pages/Admin-Dashboard/review/review-list";
import CaseStudyListPage from "./pages/Admin-Dashboard/caseStudy/caseStudy-list";
import CustomerList from "./pages/Admin-Dashboard/yourCustomer/yourCustomer-list";
// import "./index.css";
import "./App.css";
// import "../src/pages/admin-panel/admin.css";
import CheckPermission from "./axiosConfig/rbsc.config";
import Preloader from "./comp/preloader/preloader";


function App() {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);

  // Use lazy loading for components
  const Home = lazy(() => import("./pages/home/Home"));
  const About = lazy(() => import("./pages/about/About"));
  const BlogsPage = lazy(() => import("./pages/blogs/BlogsPage"));
  const BlogDetailsPage = lazy(() => import("./pages/blogs/BlogDetailsPage"));
  const Work = lazy(() => import("./pages/works/Work"));
  const Services = lazy(() => import("./pages/service/Services"));
  const Dashboard = lazy(() => import("./pages/Admin-Dashboard/dashboard"));

  return (
    <>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/blogs/:title" element={<BlogDetailsPage />} />
            <Route path="/works" element={<Work />} />
            <Route path="/services" element={<Services />} />
            <Route path="activate/:token" element={<ActivateUser />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="set-password/:token" element={<SetPassword />} />
          {/* About us is not in use */}
          <Route path="/about" element={<About />}></Route>
          <Route path="/blogs" element={<BlogsPage />}></Route>
          <Route path="/blogs/:index" element={<BlogDetailsPage />}></Route>
          {/* <Route path="/portfolio" element={<Portfolio/>}></Route> */}
          {/* <Route path="/pricing" element={<Pricing/>}></Route> */}
          {/*Our services is not in use */}
          {/* <Route path="/service" element={<Services/>}></Route> */}
          <Route path="/admin" element={ <CheckPermission accessBy={"admin"} Component={<AdminLayout />} />} >
          <Route index element={<Dashboard />} />
          <Route  path="review" element={
                <>
                  <Outlet />
                </>
              }
            >
           <Route index element={<ReviewListPage />} />
            </Route>

            <Route path="caseStudy"
              element={
                <>
                  <Outlet />
                </>
              }
            >
              <Route index element={<CaseStudyListPage />} />
            </Route>

            <Route path="customer"
              element={
                <>
                  <Outlet />
                </>
              }
            >
            <Route index element={<CustomerList />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
      {/* )} */}
    </>
  );
}

export default App;
