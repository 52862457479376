import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import ReviewService from "./review.service";
import { customStyles } from "../css/table";
import ReviewForm from "./review-form-component";
import ModalComponent from "../Modal/create-modal";
import Swal from "sweetalert2";
import "../../Admin-Dashboard/css/table.css";

const ReviewListPage = () => {
  const reviewSvc = new ReviewService();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [reviewDetails, setReviewDetails] = useState(null);

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  };

  const getReviewDetail = async (id) => {
    try {
      let response = await reviewSvc.getReviewById(id);
      setReviewDetails(response.result);
      setShowEditModal(true);
    } catch (exception) {
      console.log(exception);
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.paragraph,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            variant="success"
            size="sm"
            className="btn-circle me-1"
            onClick={() => getReviewDetail(row._id, row)}
          >
            <FaEdit />
          </Button>
          <Button
            variant="danger"
            size="sm"
            className="btn-circle  ms-2"
            onClick={() => handleDeleteClick(row._id)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleSubmit = async (values) => {
    try {
      let response = await reviewSvc.createReview(values);
      setShowCreateModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      let response = await reviewSvc.deleteReview(id);
      if (response.status) {
        await loadReview();
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleEditSubmit = async (values) => {
    try {
      let response = await reviewSvc.updateReview(values, reviewDetails._id);
      if (response.status) {
        setShowEditModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const [reviewList, setReviewList] = useState();
  // const [pagination, setPagination] = useState({
  //   currentPage: 1,
  //   perPage: 8,
  //   totalNoOfRows: 0,
  // });
  const [loading, setLoading] = useState(true);

  const loadReview = useCallback(async (perPage = 8, page = 1) => {
    try {
      let response = await reviewSvc.listAllReviews(perPage, page);
      if (response.status) {
        setReviewList(response.result);
        // setPagination(response.result);
      }
    } catch (exception) {
      console.log(exception);
    } finally {
      setLoading(false);
    }
  });

  // const handlePageChange = (page) => {
  //   loadReview(pagination.perPage, page);
  // };
  // const handlePerRowsChange = (perPage, page) => {
  //   loadReview(perPage, page);
  // };

  useEffect(() => {
    loadReview();
  }, [reviewList]);
  return (
    <>
      <Container fluid className="px-4">
        <Row>
          <Col sm={6}>
            <h3 className="mt-4">Review List </h3>
            <Button
              onClick={handleShowCreateModal}
              className={"btn btn-sm "}
              style={{ background: "#1c85c8", marginBottom: "10px" }}
            >
              <FaPlus /> Add Review
            </Button>
          </Col>
        </Row>

        <DataTable
          className="custom-table"
          columns={columns}
          data={reviewList}
          // pagination
          progressPending={loading}
          dense="dense"
          customStyles={customStyles}
          // paginationServer
          responsive="true"
          // paginationTotalRows={pagination.totalNoOfRows}
          // onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
        />
      </Container>

      <ModalComponent
        show={showCreateModal}
        onHide={handleCloseCreateModal}
        title="Create Review"
      >
        <ReviewForm submitAction={handleSubmit} />
      </ModalComponent>

      <ModalComponent
        show={showEditModal}
        onHide={handleCloseEditModal}
        title="Edit Review"
      >
        <ReviewForm detail={reviewDetails} submitAction={handleEditSubmit} />
      </ModalComponent>
    </>
  );
};

export default ReviewListPage;
