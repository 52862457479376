export const customStyles = {
  headCells: {
    style: {
      background: "#1f2128",
      color: "#ffffff",
      fontSize: "17px",
    },
  },
  rows: {
    style: {
      backgroundColor: "#1f2128",
      color: "white",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px",
      paddingRight: "15px",
      margin: "10px",
      textAlign: "center",
      fontSize: "15px",
    },
  },
};
