import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "../services/authService";

const CheckPermission = ({ Component, accessBy }) => {
  let [userInfo, setUserInfo] = useState();
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);

  const getLoggedInUser = async () => {
    try {
      let user = await Auth.authSvc.getLoggedInUser();
      setUserInfo(user.result);
    } catch (error) {
      //   toast.error("Please Login First");
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLoggedInUser();
  }, []);

  if (error) {
    return <Navigate to={"/login"} />;
  } else {
    if (loading) {
      return <>Loading</>;
    } else if (!loading && userInfo && userInfo.role === accessBy) {
      return Component;
    } else {
      //   toast.warning("You do not have permission to access this page");
      return <Navigate to={"/" + "admin"} />;
    }
  }
};

export default CheckPermission;
