import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./about.css";
import CancelIcon from "@mui/icons-material/Cancel";

const About = () => {
  const [aboutPop, setAboutPop] = useState(false);

  //This logic is to hide overflowY
  // useEffect(() => {
  //   document.body.style.overflowY = "hidden";
  //   return () => {};
  // }, []);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <div id="about" className="lg:w-8/12 w-11/12 mx-auto">
        <div className="about_content_holder">
          <div className="about_left">
            {/* <img className="butterfly_bg" src="handbg.png" alt="" /> */}
            <img
              className="creative_butterfly"
              src="about-animation.gif"
              alt="animated butterfly"
            />
          </div>
          <div className="about_right">
            <h1
              className="about_title"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Our <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#E10B7B] via-[#652D90 ] to-[#1C85C7]">Story</span>
            </h1>
            <p className="our_story_para ">
              Welcome to LogoLab, where creativity meets innovation! At LogoLab,
              we are passionate about crafting unique and impactful logos that
              tell your brand's story.
            </p>
            <div className="about_us_btn_holder">
              <button
                className="about_us_btn"
                data-aos="fade-up"
                data-aos-duration="1000"
                onClick={() => setAboutPop(true)}
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
      {aboutPop && (
        <>
          <div
            className="model_wrapper"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="more_about_us">
              <h2 className="text-sm font-bold uppercase">
                Read More About Us
              </h2>
              <button
                className="close_me_btn"
                onClick={() => setAboutPop(false)}
              >
                <CancelIcon />
              </button>
            </div>
            <div className="about_more_details_pop">
              <p className="about_popup_paragraph">
                Welcome to LogoLab, where creativity meets innovation! At
                LogoLab, we are passionate about crafting unique and impactful
                logos that tell your brand's story. Our team of skilled
                designers combines artistry with strategic thinking to deliver
                logos that not only captivate but also resonate with your
                audience. Whether you're a startup looking for a distinctive
                identity or an established business seeking a rebrand, LogoLab
                is your creative partner on the journey to visual excellence.
                Let's transform your ideas into remarkable logos that leave a
                lasting impression. Elevate your brand with LogoLab – where
                every design is a masterpiece! Our team of imaginative designers
                at LogoLab is dedicated to bringing your ideas to life with
                flair and originality. Whether you're launching a new venture or
                aiming to refresh your image, we're here to create a logo that
                resonates. Let LogoLab be your go-to for innovative design
                solutions that speak volumes about your brand identity. Unleash
                the power of creativity with LogoLab – where every logo is a
                work of art!
              </p>
              <p>Thank You For Your Read</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default About;
