import React from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./forget-password.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "../../services/authService";
import { useNavigate } from "react-router-dom";

function SetPassword() {
  const SetPasswordSchema = Yup.object({
    newPassword: Yup.string().min(6).required(),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm New Password is required"),
  });

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem("email"),
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: SetPasswordSchema,
    onSubmit: async (values) => {
      try {
        const response = await Auth.authSvc.resetPassword(
          values.email,
          values.newPassword
        );
        navigate("/login");
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <>
      <Container fluid className="forget-container">
        <Form onSubmit={formik.handleSubmit} style={{ width: "50vh" }}>
          <h3>Set your password</h3>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="newPassword"
              onChange={formik.handleChange}
            />
            <span className="text-danger">
              {formik.errors && formik.errors.newPassword}
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmNewPassword">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="confirmNewPassword"
              onChange={formik.handleChange}
            />
            <span className="text-danger">
              {formik.errors && formik.errors.confirmNewPassword}
            </span>
          </Form.Group>
          <Button
            variant="dark"
            type="submit"
            className="forget_btn"
            style={{ width: "175px" }}
          >
            Set new password
          </Button>
        </Form>
      </Container>
    </>
  );
}

export default SetPassword;
