import client1 from "../../assets/clientimages/nepali1.jpg"
import client2 from "../../assets/clientimages/nepali2.jpg"
import client3 from "../../assets/clientimages/nepali3.jpg"
import client4 from "../../assets/clientimages/nepali4.jpg"
import client5 from "../../assets/clientimages/nepali5.jpg"
import client6 from "../../assets/clientimages/nepali6.jpg"
import client7 from "../../assets/clientimages/nepali7.jpg"
import client8 from "../../assets/clientimages/review02.jpg"
import client9 from "../../assets/clientimages/review05.jpg"

const clientData = [
  {
    id: 1,
    name: "Neeshant Tamang",
    image:client1,
    paragraph: `“ 🏆 Unquestionably the finest logo design experts in Nepal! Your logo, your identity, perfected. ”`,
  },
  {
    id: 2,
    name: "Aman Ghimire",
    image: client8,
    paragraph: `“Totally impressed by your creativity.”`,
  },
  {
    id: 3,
    name: "Kamlesh Shrestha ",
    image: client9,
    paragraph: `“They are young but have done an absolutely great job for my company's logo design.”`,
  },
  {
    id: 4,
    name: "Yugesh Chaudhari",
    image: client1,
    paragraph: `“Choosing your services for us is best. Responsibility for client satisfaction is great! ”`,
  },
  {
    id: 5,
    name: "Gehendra Yadav",
    image: client2,
    paragraph: `“ They do this absolutely awesome job to make the graphics neat and fit in with the brand identity.
 ”`,
  },
  {
    id: 6,
    name: "Suraj Jung Thapa",
    image: client4,
    paragraph: `“They take things into account and works on it. Great job guys!”`,
  },
  {
    id: 7,
    name: "Suzan Shrestha",
    image: client1,
    paragraph: `“ Logolab ko vai harule chai J hos malai chittabujhdo logo banayera diyo. Best lagyo! ”`,
  },
  {
    id: 8,
    name: "Suresh Thapa",
    image: client6,
    paragraph: `“ They have a very good understanding of branding and they make logo passionately. Keep up the great work guys! ”`,
  },
];

export default clientData;
