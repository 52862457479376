import React from "react";
import "./preloader.css";

const Preloader = () => {
  return (
    <div className="spinner">
      <div className="cube1"></div>
      <div className="cube2"></div>
    </div>
  );
};

export default Preloader;
