import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Auth } from "../../services/authService";
// import { toast } from "react-toastify";

const ActivateUser = () => {
  let params = useParams();
  let navigate = useNavigate();
  const loadUser = async () => {
    try {
      let user = await Auth.authSvc.getUserByToken(params.token);
      // toast.success("Your account has been activated. Please login");
      navigate("/login");
    } catch (exception) {
      // let message = exception.data.msg ?? "Cannot activate account now";
      console.log(exception);
      // toast.warning(message);
    }
  };
  useEffect(() => {
    loadUser();
  }, []);
  return <></>;
};

export default ActivateUser;
