import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import CallIcon from "@mui/icons-material/Call";
import "./footer.css";
import MapImage from './ktm_map.jpg'
import LogoImg from "../navbar/logo.png"


//social media links:
const logolab_facebook =
  "https://www.facebook.com/profile.php?id=100063752927822";

const Footer = () => {
  const phoneNumber = 9765978888;

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <div className="main_footer">
      <h1 className="footer_title" data-aos="fade-up" data-aos-duration="1000">
        Let's Work Together
      </h1>
      <div
        className="footer_button_box"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <button className="footer_btn" onClick={handleCallClick}>
          <CallIcon className="call_icon" />
          Call Now
        </button>
      </div>
      <hr className="hr_line" />
      <div className="footer_content">
        <div className="footer_left">
          <div className="footer_map_and_adresses">
            <div className="footer_left_address_maps">
              <div className="footer_logo_left">
                <img src={LogoImg} alt="logo" width={200} />
              </div>
              <p className="footer_address">
                {/* <MdLocationPin className="location_icon" size={24} /> */}
                <span>Kathmandu,</span> <br />
                <span className="samakhushi_margin">Samakhushi</span>
              </p>
            </div>
            <div className="footer_map_area">
              <img
                className="ktm_google_map_img"
                src={MapImage}
                alt="googleMap"
              />
              <a
                className="open_map"
                href="https://goo.gl/maps/GYVdWWdbGfb9h5k9A"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click For Map
              </a>
            </div>
          </div>
        </div>
        <div className="footer_mid">
          <a href="/">Find Us At</a>
          {/* <a href="/">Our Services</a>
          <a href="/">Case Studies</a> */}
        </div>
        <div className="footer_right">
          <a href={logolab_facebook} target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
          <a
            href="https://instagram.com/logolabnepal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://linkedin.com/company/logolabnepal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </a>
          <a
            href="https://wa.me/9765978888"
            target="_blank"
            rel="noopener noreferrer"
          >
            WhatsApp
          </a>
        </div>
      </div>
      <div className="footer_header">
        <div className="copyright">
          <p className="copyright">
            &#169; Copyright 2023. All Right Reserved{" "}
            <span className="foobar_footer" style={{ opacity: 0.5 }}>
              |
            </span>
            <a target="_blank" rel="noreferrer" href="https://webxnep.com">
              Designed By WebX
            </a>
          </p>
        </div>
        <a className="goUp" href="#navbar">
          <AiOutlineArrowUp color="white" size={20} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
