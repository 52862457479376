import React from "react";
import { Container, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./forget-password.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "../../services/authService";

const ForgetPassword = () => {
  const LoginSchema = Yup.object({
    email: Yup.string().email().required(),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const response = await Auth.authSvc.forgetPassword(values.email);
        localStorage.setItem("email", values.email);
        if (response.status === true) {
          console.log(response.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <>
      {/* <Container
        fluid
        className="forget-container"
        style={{
          display: "flex",
          justifyContent: "center",
          height: "45vh",
          marginTop: "20px",
          width: "40%",
        }}
      >
        <Row> */}
      <Container fluid className="forget-container">
        {/* <h2>Reset Your Password</h2> */}
        <h4>Enter your email</h4>
        <p>
          Enter your email and we will send you a confirmation link on email
        </p>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              onChange={formik.handleChange}
            />
            <span className="text-danger">
              {formik.errors && formik.errors.email}
            </span>
          </Form.Group>
          <Button variant="dark" type="submit" className="forget_btn">
            Submit
          </Button>
        </Form>

        <p>
          Go back to <Link to="/login">Login Page</Link>
        </p>
      </Container>
      {/* </Row>
      </Container> */}
    </>
  );
};

export default ForgetPassword;
