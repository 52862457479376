import React, { useEffect } from "react";
import { Form, Col, Button, Container, Row } from "react-bootstrap";
import { TextInput } from "../../Admin-Dashboard/components/form.component";
import { FaPaperPlane } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";

const CaseStudyForm = ({ submitAction, detail = null }) => {
  const validationSchema = Yup.object({
    title: Yup.string().min(5).required(),
    paragraph: Yup.string().required(),
    image: Yup.string().required(),
  });
  let formik = useFormik({
    initialValues: {
      title: "",
      paragraph: "",
      image: "",
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      submitAction(values);
    },
  });

  useEffect(() => {
    if (detail) {
      let data = {
        title: detail.title,
        paragraph: detail.paragraph,
        image: detail.image,
        status: detail.status,
      };
      formik.setValues({
        ...formik.values,
        ...data,
      });
    }
  }, [detail]);

  return (
    <>
      <Container
        fluid
        className="px-4 text-white"
        style={{ height: "50vh", background: "black" }}
      >
        <Row>
          <Col sm={12} md={12}>
            <h4 className="m-4 text-center"></h4>
          </Col>
        </Row>
        <Form onSubmit={formik.handleSubmit} style={{ marginBottom: "10px" }}>
          <TextInput
            label="Title"
            name="title"
            value={formik.values.title}
            changeEvent={formik.handleChange}
            placeholder="Enter Title"
            error={formik.errors.title}
          />
          <TextInput
            label="Content"
            name="paragraph"
            value={formik.values.paragraph}
            changeEvent={formik.handleChange}
            placeholder="Write your content.."
            error={formik.errors.paragraph}
          />
          <Form.Group className="row mb-3">
            <Form.Label className="col-sm-3">Images: </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="file"
                size="sm"
                name="image"
                onChange={(e) => {
                  let file = e.target.files[0];
                  let ext = file.name.split(".").pop();

                  if (
                    [
                      "jpg",
                      "jpeg",
                      "png",
                      "gif",
                      "bmp",
                      "webp",
                      "svg",
                    ].includes(ext.toLowerCase())
                  ) {
                    formik.setValues({
                      ...formik.values,
                      image: file,
                    });
                  } else {
                    formik.setErrors({
                      ...formik.errors,
                      image: "File format not supported",
                    });
                  }
                }}
                accept="image/*"
              />
              <span className="text-danger">{formik.errors.image}</span>
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Form.Label className="col-sm-3">Status: </Form.Label>
            <Col sm={9}>
              <Form.Select
                name="status"
                value={formik.values.status}
                required
                onChange={formik.handleChange}
                size="sm"
              >
                <option>-- Select Any one --</option>
                <option value={"active"}>Publish</option>
                <option value={"inactive"}>Un-Publish</option>
              </Form.Select>
              <span className="text-danger">{formik.errors.status}</span>
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Col sm={{ offset: 3, span: 9 }}>
              <Button
                variant="success"
                className="me-3"
                type="submit"
                size="sm"
              >
                <FaPaperPlane />
                Submit
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};

export default CaseStudyForm;
