import React from "react";
import { FaUser } from "react-icons/fa";
import "./index.css";

import logo from "./images/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
const Topbar = ({ isSidebarCollapsed }) => {
  const navigate = useNavigate();
  const topbarClass = isSidebarCollapsed
    ? "collapsed-topbar"
    : "expanded-topbar";

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    navigate("/login");
    // toast.success("Thank you for using our system");
  };

  return (
    <>
      <div className="top">
        <div className={`${topbarClass}`}>
          <Link to={"/"}>
            <img src={logo} alt="logo" srcset="" style={{ width: "100px" }} />
          </Link>
          {/* <div className="icons">
            <FaUser size={24} />
          </div> */}
          <ul
            style={{
              paddingBottom: "0px",
              paddingTop: "0px",
              marginRight: "20px",
              marginTop: "0",
              marginBottom: "0",
            }}
          >
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaUser size={20} />
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <NavLink
                    onClick={logout}
                    className="dropdown-item"
                    to="/login"
                  >
                    Logout
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Topbar;
