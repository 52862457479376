import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import axiosInstance from "../../axiosConfig/axios.config";

const Login = () => {
  const navigate = useNavigate();
  const LoginSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required(),
  });
  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        let response = await axiosInstance.post("/v1/auth/login", values);
        if (response.status) {
          let formattedData = {
            id: response.result.data._id,
            name: response.result.data.name,
            email: response.result.data.email,
            role: response.result.data.role,
          };
          localStorage.setItem(
            "accessToken",
            response.result.token.accessToken
          );
          localStorage.setItem(
            "refreshToken",
            response.result.token.refreshToken
          );
          localStorage.setItem("user", JSON.stringify(formattedData));
          navigate("/" + "admin");
        } else {
          console.log(response.msg);
        }
      } catch (exception) {
        console.log(exception);
      }
    },
  });
  return (
    <>
      <Container className="login-container">
        <h2 className="heading">Login to Continue</h2>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              onChange={formik.handleChange}
            />
            <span className="text-danger">
              {formik.errors && formik.errors.email}
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              onChange={formik.handleChange}
            />
            <span className="text-danger">
              {formik.errors && formik.errors.password}
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Link to="/forget-password">Forget Password</Link>
          </Form.Group>
          <Button className="login_btn" type="submit">
            Login
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default Login;
