import React, { useEffect, useState } from "react";
import { blogdata } from "./blogsdata";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { truncateDescription } from "./utils";
import { supabase } from "../../utils/supabase";
const BlogCard = () => {
  const visibleBlogs = blogdata.slice(0, 3);
  const [affiliation, setAffiliation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEmployment = async () => {
    setIsLoading(true);
    let { data, error } = await supabase
      .from("Fields")
      .select(
        "sectionName,id,Contents (id,content->>Intro,content->>Title,content->>Date,content->>Category),Media(image,id)"
      )
      .eq("subId", 35);
    if (error) {
      setIsLoading(false);
      console.log("Error");
    } else {
      setIsLoading(false);
      // console.log(data)
      setAffiliation(data);
    }
  };
  useEffect(() => {
    getEmployment();
  }, []);
  return (
    <main>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items-center gap-x-4 gap-y-6 my-10">
        {affiliation?.map((item, index) => (
          <Link to={`/blogs/${encodeURIComponent(item?.Contents[0]?.Title)}`}>
            <div
              key={index}
              className="rounded-2xl p-4 max-w-[29em] grid place-items-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="relative group">
                {item?.Media[0]?.image?.Image?.length !== 0 && (
                  <figure className="overflow-hidden w-84 h-60">
                    <img
                      src={item?.Media[0]?.image?.Image[0]}
                      alt={item.Contents[0]?.Title}
                      className="w-full h-full object-cover group-hover:brightness-50 group-hover:scale-125 duration-300 ease-in-out cursor-pointer "
                    />
                  </figure>
                )}
                <p className="about-us-btn absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  rounded-2xl px-4 py-1 bg-gray-800 bg-opacity-40  backdrop-filter backdrop-blur-sm hidden group-hover:block cursor-pointer">
                  Read more
                </p>
              </div>
              <h2 className="text-2xl font-semibold pt-2">
                {item?.Contents[0]?.Title.slice(0, 60)}
                {item?.Contents[0]?.Title?.length > 60 && "..."}
              </h2>
              <p className="text-gray-500 py-2">
                {item?.Contents[0]?.Intro.length > 120
                  ? parse(item.Contents[0].Intro.slice(0, 120) + "...")
                  : parse(item.Contents[0].Intro)}
              </p>
              <div className="w-full flex justify-between text-gray-500">
                <p className="font-semibold">{item?.Contents[0]?.Date}</p>
                <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#E10B7B] via-[#652D90 ] to-[#1C85C7]">
                  #{item?.Contents[0]?.Category}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </main>
  );
};

export default BlogCard;
