import React, { useEffect, useState } from "react";
import workImages from "./imageData";
import Aos from "aos";
import "aos/dist/aos.css";
import "./works.css";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import ImageModal from "../../pages/works/ImageModal";

const Works = () => {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setModalImageIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNextImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex + 1) % workImages.slice(0,6).length);
  };

  const handlePrevImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex - 1 + workImages.slice(0,6).length) % workImages.length);
  };
  return (
    <div className="lg:w-10/12 w-10/12 mx-auto my-10">
      <h1 className="lg:text-5xl md:text-3xl text-2xl font-bold text-center" data-aos="fade-up" data-aos-duration="1000">
        OUR <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#E10B7B] via-[#652D90 ] to-[#1C85C7]">WORKS</span>
      </h1>
      <div className="lg:w-10/12 w-12/12 mx-auto">
      <div data-aos="fade-up" data-aos-duration="1000">
        <section className="my-20 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-4 gap-x-6" >
          {workImages.slice(0,6).map((data, index) => (
            <div key={index} className={`${index % 3 === 1 ? "lg:mt-12" : ""}`} onClick={() => handleImageClick(index)}>
              <figure className="overflow-hidden w-92 rounded-2xl  ease-in-out duration-300">
                <img
                  src={data.image}
                  alt="data"
                  className="hover:scale-125  ease-in-out duration-300 "
                />
              </figure>
            </div>
          ))}
        </section>
        <div className='flex justify-center'>
        <button className='flex items-center gap-1 animate-bounce'>
            <Link to="/works" className=''>View More</Link>
            <div className="border rounded-full p-1 text-l mb-4">
            <FiArrowUpRight />
            </div>
        </button>
        </div>
      </div>
      </div>

       {/* Image Modal */}
       {isModalOpen && (
          <ImageModal
            images={workImages.map(data => data.image)}
            currentIndex={modalImageIndex}
            onClose={handleCloseModal}
            onNext={handleNextImage}
            onPrev={handlePrevImage}
          />
        )}
      {/* <div className="responsive-slider">
        <PortfolioSlider />
      </div>
      <p className="mt-20 text-center text-gray-400 sm:hidden font-semibold">Swipe Right</p> */}
    </div>
  );
};

export default Works;
