import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./about.css";

const About = () => {

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  });
  return (
    <div className="about_main_about_section">
      <h1
        className="about_section_heading"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Know How we are.
      </h1>
      <p
        className="about_section_paragraph"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        At Logolab, we believe that behind every successful business, there's a
        captivating story waiting to be told. Our journey began with a
        passionate team of creative minds who understood the power of branding
        to transform mere businesses into iconic brands. Founded in 2022,
        Logolab Nepal pvt. ltd was born out of a vision to revolutionize the way
        businesses connect with their audiences. Our founder, Krishna G. Mul,
        had a simple yet profound belief that branding is not just about
        creating logos and visuals it's about crafting an identity that
        resonates with hearts and minds. From our humble beginnings in a small
        studio, we've grown into a thriving hub of innovation, where designers,
        strategists, and storytellers collaborate to shape brand narratives that
        leave a lasting impact. Our journey has been one of evolution, marked by
        countless brainstorming sessions, daring experiments, and lessons that
        fueled our growth. What sets Logolab Nepal apart is our unwavering
        commitment to authenticity. it's about strategy. We're not satisfied
        with creating visuals that merely look good; we aim to create visuals
        that strategically communicate and resonate. Our approach is holistic,
        combining aesthetics with market insights, psychology, and innovation.
        We're not just designers we're building dreams. Welcome to the art of
        transformative branding.
      </p>
      <div
        className="about_our_team"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h1 className="about_team_title">Our Team</h1>
        <div className="about_team_details_container">
          <div className="about_team_items">
            <img className="team_image" src="krishna.jpg" alt="teamDetails" />
            <h3 className="about_team_name">Krishna G. Mul</h3>
            <p className="about_team_deg">COO</p>
          </div>
          <div className="about_team_items">
            <img className="team_image" src="krishna.jpg" alt="teamDetails" />
            <h3 className="about_team_name">Krishna G. Mul</h3>
            <p className="about_team_deg">COO</p>
          </div>
          <div className="about_team_items">
            <img className="team_image" src="krishna.jpg" alt="teamDetails" />
            <h3 className="about_team_name">Krishna G. Mul</h3>
            <p className="about_team_deg">COO</p>
          </div>
          <div className="about_team_items">
            <img className="team_image" src="krishna.jpg" alt="teamDetails" />
            <h3 className="about_team_name">Krishna G. Mul</h3>
            <p className="about_team_deg">COO</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
