import React, { useState, useRef } from 'react';
import clientData from '../clientData';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { AiFillStar } from "react-icons/ai";
import mark from "./mark.png";
import mark2 from "./mark2.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Client = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null); 

    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    const handlePrev = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        centerMode: true,
        // dots: true,
        centerPadding: "0px",
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
        responsive: [
          {
              breakpoint: 1024, // Settings for screens less than 1024px
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
              }
          },
          {
              breakpoint: 600, // Settings for screens less than 600px
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: false,
              }
          },
      ],
        appendDots: dots => (
          <div style={{ bottom: '-50px' }}>
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        customPaging: i => (
          <div
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: currentSlide === i ? "white" : "rgba(255, 255, 255, 0.5)",
              display: "inline-block",
              margin: "0 5px",
            }}
          />
        ),
    };

    return (
        <div className="lg:w-10/12 w-12/12 mx-auto my-10">
            <h1 className="lg:text-5xl md:text-3xl text-2xl font-bold text-center"data-aos="fade-up" data-aos-duration="1000">
                What our <span className='bg-clip-text text-transparent bg-gradient-to-r from-[#E10B7B] via-[#652D90 ] to-[#1C85C7]'>Clients</span> say?
            </h1>
            <div className='slider-container md:my-20 my-8 mx-2' data-aos="fade-up" data-aos-duration="1000">
                <Slider {...settings} ref={sliderRef}>
                    {clientData.map((data, index) => (
                        <div key={index} className={`${index === currentSlide ? "lg:scale-110" : "  scale-75 brightness-50"}  rounded-2xl my-4 py-4 px-4 transition-transform duration-500 ease-in-out border`}>
                            <div className="flex justify-between items-center"> 
                                <section className="flex items-center gap-6">
                                    <img src={data.image} className="w-20 h-20 object-cover rounded-full" alt={data.title}/>
                                    <div>
                                        <h2 className="font-bold text-[16px]">{data.name}</h2>
                                        <section className="flex text-yellow-400">
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <span key={index}><AiFillStar className="star_icon" /></span>
                                            ))}
                                        </section>
                                    </div>
                                </section>
                            </div>
                            <div className='mt-4'>
                                <img src={mark} alt="quote" className='w-4' />
                                <p className="text-thirdwhite font-regular text-[14px] px-6">{data.paragraph}</p>
                                <figure className='flex justify-end'>
                                    <img src={mark2} alt="quote" className='w-4' />
                                </figure>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="flex text-4xl items-center gap-4 absolute left-1/2 -translate-x-1/2 -bottom-10">
                    <button onClick={handlePrev} className="cursor-pointer border rounded-full bg-white text-black duration-300 active:!bg-black active:!text-white">
                        <MdKeyboardArrowLeft />
                    </button>
                    <button onClick={handleNext} className="cursor-pointer border rounded-full bg-white text-black duration-300 active:!bg-black active:!text-white">
                        <MdKeyboardArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Client;
