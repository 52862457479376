import React from "react";
import { Outlet } from "react-router-dom";
import Topbar from "./global/topbar";
import Sidebar1 from "./global/sidebar";
import "./css/adminlayout.css";

function AdminLayout() {
  return (
    <>
      <div className="admin-layout">
        <Sidebar1 />
        <div className="layout-container">
          <Topbar />
          <div className="content-container">
            <main>
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLayout;
