import React, { useEffect } from "react";
import MetaTags from "../../comp/MetaTags";
import Hero from "../../comp/hero-section/Hero";
import About from "../../comp/about-us/About";
import Works from "../../comp/our-works/Works";
import Blogs from "../../comp/blogs/Blogs";
import Client from "../../comp/clients-comments/clientResponsiveCarousel/Client";
import { useLocation } from "react-router-dom";


const Home = () => {
  const pathname = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname])
  return (
    <>
      <MetaTags
        title="Best logo designing company in Nepal | LogoLab Nepal"
        description="LogoLab Nepal: The best for logo design in Nepal. As a top logo maker, we craft creative and professional business logo designs to elevate your brand."
        url="https://logolabnepal.com/"
        image="https://logolabnepal.com/logo.png"
      />
      <Hero />
      <About />
      <Works />
      <Blogs />
      <Client />

    </>
  );
};

export default Home;
