import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import CaseStudyService from "./caseStudy.service";
import { customStyles } from "../../Admin-Dashboard/css/table";
import CaseStudyForm from "./caseStudy-form-component";
import ModalComponent from "../Modal/create-modal";
import Swal from "sweetalert2";
import "../../Admin-Dashboard/css/table.css";

const CaseStudyListPage = () => {
  const caseStudySvc = new CaseStudyService();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [caseStudyDetails, setCaseStudyDetails] = useState(null);

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  };

  const getCaseStudyDetail = async (id) => {
    try {
      let response = await caseStudySvc.getCaseStudyById(id);
      setCaseStudyDetails(response.result);
      setShowEditModal(true);
    } catch (exception) {
      console.log(exception);
    }
  };
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.paragraph,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            variant="success"
            size="sm"
            className="btn-circle me-1"
            onClick={() => getCaseStudyDetail(row._id, row)}
          >
            <FaEdit />
          </Button>
          <Button
            variant="danger"
            size="sm"
            className="btn-circle ms-2"
            onClick={() => handleDeleteClick(row._id)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleSubmit = async (values) => {
    try {
      let response = await caseStudySvc.createCaseStudy(values);
      setShowCreateModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      let response = await caseStudySvc.deleteCaseStudy(id);
      if (response.status) {
        await loadCaseStudy();
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleEditSubmit = async (values) => {
    try {
      let response = await caseStudySvc.updateCaseStudy(
        values,
        caseStudyDetails._id
      );

      if (response.status) {
        setShowEditModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const [caseStudyList, setCaseStudyList] = useState();
  // const [pagination, setPagination] = useState({
  //   currentPage: 1,
  //   perPage: 8,
  //   totalNoOfRows: 0,
  // });
  const [loading, setLoading] = useState(true);

  const loadCaseStudy = useCallback(async (perPage = 8, page = 1) => {
    try {
      let response = await caseStudySvc.listAllCaseStudy(perPage, page);
      if (response.status) {
        setCaseStudyList(response.result);
        // setPagination(response.result);
      }
    } catch (exception) {
      console.log(exception);
    } finally {
      setLoading(false);
    }
  });

  // const handlePageChange = (page) => {
  //   loadCaseStudy(pagination.perPage, page);
  // };
  const handlePerRowsChange = (perPage, page) => {
    loadCaseStudy(perPage, page);
  };

  useEffect(() => {
    loadCaseStudy();
  }, [caseStudyList]);
  return (
    <>
      <Container fluid className="px-4">
        <Row>
          <Col sm={6}>
            <h3 className="mt-4">CaseStudy List </h3>
            <Button
              onClick={handleShowCreateModal}
              className={"btn btn-sm "}
              style={{ background: "#1c85c8", marginBottom: "10px" }}
            >
              <FaPlus /> Add CaseStudy
            </Button>
          </Col>
        </Row>

        <DataTable
          className="custom-table"
          columns={columns}
          data={caseStudyList}
          // pagination
          progressPending={loading}
          dense="dense"
          customStyles={customStyles}
          // paginationServer
          responsive="true"
          // paginationTotalRows={pagination.totalNoOfRows}
          onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
        />
      </Container>

      <ModalComponent
        show={showCreateModal}
        onHide={handleCloseCreateModal}
        title="Create CaseStudy"
      >
        <CaseStudyForm submitAction={handleSubmit} />
      </ModalComponent>

      <ModalComponent
        show={showEditModal}
        onHide={handleCloseEditModal}
        title="Edit CaseStudy"
      >
        <CaseStudyForm
          detail={caseStudyDetails}
          submitAction={handleEditSubmit}
        />
      </ModalComponent>
    </>
  );
};

export default CaseStudyListPage;
