import work1 from "../../assets/workImages/work1.jpg"
import work2 from "../../assets/workImages/work2.jpg"
import work3 from "../../assets/workImages/work3.jpg"
import work4 from "../../assets/workImages/work4.jpg"
import work5 from "../../assets/workImages/work5.jpg"
import work6 from "../../assets/workImages/work6.jpg"
import work7 from "../../assets/workImages/work7.jpg"
import work8 from "../../assets/workImages/work8.jpg"
import work9 from "../../assets/workImages/work9.jpg"

const workImages = [
  { id: 1, image:work1, alt: "brand" },
  { id: 2, image:work2, alt: "logo" },
  { id: 3, image: work3, alt: "logo" },
  { id: 4, image:work4, alt: "logo" },
  { id: 5, image:work5, alt: "commercial" },
  { id: 6, image: work6, alt: "commercial" },
  { id: 7, image:work7, alt: "commercial" },
  { id: 8, image:work8, alt: "brand" },
  { id: 9, image:work9, alt: "brand" },
];

export default workImages;
