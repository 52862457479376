import React, { useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Blogs.css";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import BlogCard from "./BlogCard";

const Blogs = () => {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <div id="blogs" className="lg:w-10/12 w-12/12 mx-auto my-20">
      <h1
        className="lg:text-5xl md:text-3xl text-2xl font-bold text-center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        OUR{" "}
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#E10B7B] via-[#652D90 ] to-[#1C85C7]">
          BLOGS
        </span>
      </h1>
      <p
        className="text-center text-gray-500 pt-4"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        We share our thoughts and experience with the people who is interested
        on Creativity.
      </p>
      <div>
        <BlogCard />
      </div>
      <div className="flex justify-center text-lighttext hover:text-white">
        <button className="flex items-center gap-1 animate-bounce text-lighttext hover:text-white">
          <Link to="/blogs" className="">
            View More
          </Link>
          <div className="border rounded-full p-1 text-l mb-4">
            <FiArrowUpRight />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Blogs;
