import React from "react";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import { Outlet } from "react-router-dom";
import Headroom from "react-headroom";

const Layout = ({ children }) => {
  return (
    <>
    <Headroom>
       <Navbar/>
    </Headroom>
      <main>{children}</main> 
            <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
