import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./hero.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";import { useGSAP } from '@gsap/react'
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitType from 'split-type'

const Hero = () => {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);
  return (
    <div className="main_hero" id="home">
      <div className="hero_content_holder_container relative">
        <img className="golden_ratio" src="colorful.gif" alt="golden ratio gif" />
        <h1 className="content uppercase font-bold">
        LOGOS ARE DESIGNED IN STUDIOS.
          <br />
          BRANDS ARE CREATED IN MINDS.
</h1>
        <a
          href="https://wa.me/9765978888"
          className="border-2 border-green-500 rounded-full py-2 px-4 md:hidden my-5"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsAppIcon className="whatsapp_icon" />
          WhatsApp Us
        </a>
        <div className="scroll_animation">
          <span className="scroll_down"></span>
          <p>Scroll Down</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
