import axiosInstance from "../../axiosConfig/axios.config";

class AuthService {
  login = async (credentials) => {
    try {
      let response = await axiosInstance.post("/v1/auth/login", credentials);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getUserByToken = async (token) => {
    try {
      let response = await axiosInstance.post(
        "/v1/auth/activate/" + token,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  getLoggedInUser = async () => {
    try {
      let token = localStorage.getItem("accessToken");
      if (!token) {
        throw "Token not set..";
      }
      let userInfo = await axiosInstance.get("/v1/auth/me", {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      return userInfo;
    } catch (error) {
      throw error;
    }
  };
  forgetPassword = async (email) => {
    try {
      const response = await axiosInstance.post(
        "/v1/auth/forget-password",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  resetPassword = async (userEmail, newPassword) => {
    try {
      const response = await axiosInstance.post(
        "/v1/auth/password-reset",
        { email: userEmail, newPassword },
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status) {
        return response.data;
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      throw error;
    }
  };
}

export default AuthService;
