import React, { useState, useEffect, useRef } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Logo from "./logo.png"
const Nav = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Our Works",
    path: "/works",
  },
  {
    title: "Blogs",
    path: "/blogs",
  },
  {
    title: "Services",
    path: "/services",
  },
];

const Navbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const hamburgermenuRef = useRef();
  const [isOpen, setOpen] = useState(false);

  //to close the navbar once desired route is achieved
  const handleLinkClick = () => {
    setOpen(false);
  };

  useGSAP(() => {
    if (isOpen) {
      gsap.from(hamburgermenuRef.current.children, {
        opacity: 0,
        x: 60,
        stagger: 0.1,
        duration: 0.5,
      });
    }
  }, [isOpen]);

  return (
    <main>
      <div id="navbar" className="navbar_main">
        <div className="nav_container">
          <div className="lab_navbar_logo">
            <Link to="/">
              <img id="brand_logo" src={Logo} alt="logolab logo" />
            </Link>
          </div>
          <div className="nav_items">
            {Nav.map((data, index) => (
              <div key={index}>
                <Link
                  to={data.path}
                  className={`${
                    currentPath === data.path
                      ? "text-white font-semibold"
                      : "text-lighttext"
                  }`}
                >
                  {data.title}
                </Link>
              </div>
            ))}
          </div>

          <div className="whatsapp_btn_container">
            <a
              href="https://wa.me/9765978888"
              className="main_btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon className="whatsapp_icon" />
              WhatsApp Us
            </a>
          </div>

          <div className="lg:hidden cursor-pointer">
            {isOpen ? (
              <RiCloseLine
                color="white"
                size={27}
                onClick={() => setOpen(!isOpen)}
              />
            ) : (
              <HiOutlineMenuAlt3
                color="white"
                size={27}
                onClick={() => setOpen(!isOpen)}
              />
            )}
          </div>
        </div>
      </div>

      {/* responsive part */}
      <>
        <div
          className={`${
            isOpen ? "absolute lg:hidden" : "hidden"
          } left-1/2 -translate-x-1/2 top-[3em] w-full  h-[105vh] bg-black`}
        >
          <div
            className="grid place-items-center gap-10 pt-20 my-auto"
            ref={hamburgermenuRef}
          >
            {Nav.map((data, index) => (
              <div key={index} className="text-2xl">
                <Link
                  to={data.path}
                  className={`${
                    currentPath === data.path
                      ? "text-white font-bold "
                      : "text-lighttext"
                  }`}
                  onClick={handleLinkClick}
                >
                  {data.title}
                </Link>
              </div>
            ))}

            <a
              href="https://wa.me/9765978888"
              className="border-2 border-green-500 rounded-full px-5 py-2 my-2 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon className="whatsapp_icon text-2xl text-green-500" />
              WhatsApp Us
            </a>
          </div>
        </div>
      </>
    </main>
  );
};

export default Navbar;
