import HttpService from "../../../axiosConfig/http.service";

export default class CustomerService extends HttpService {
  createCustomer = async (data) => {
    try {
      let response = await this.postRequest("/v1/customer", data, {
        auth: true,
        file: true,
      });
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  listAllCustomer = async (perPage = 3, page = 1) => {
    try {
      let response = await this.getRequest(
        "/v1/customer?perPage=" + perPage + "&page" + page
      );
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  getCustomerDetail = async (slug) => {
    try {
      let response = await this.getRequest("/v1/customer/" + slug + "/detail");
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  deleteCustomer = async (id) => {
    try {
      let response = await this.deleteRequest("/v1/customer/" + id, {
        auth: true,
      });
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  updateCustomer = async (data, id) => {
    try {
      let response = await this.putRequest("/v1/customer/" + id, data, {
        auth: true,
        file: true,
      });
      return response;
    } catch (exception) {
      throw exception;
    }
  };

  getCustomerById = async (id) => {
    try {
      let response = await this.getRequest("/v1/customer/" + id, {
        auth: true,
      });
      return response;
    } catch (exception) {
      throw exception;
    }
  };
}
